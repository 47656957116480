'use client';

import { Spinner, Flex } from '@chakra-ui/react';

const RootLoading = () => {
  return (
    <Flex
      w="100vw"
      h="100vh"
      alignItems="center"
      justifyContent="center"
      py="2rem"
    >
      <Spinner color="primary.100" />
    </Flex>
  );
};

export default RootLoading;
